<template>
  <div>
    <v-data-table
      dense
      sort-by="name"
      :headers="headers"
      :itemsPerPage="User.length"
      :expanded.sync="expanded"
      show-expand
      :loading="isLoading"
      hide-default-footer
      :items="User"
    >
      <template v-slot:item="{ item, isExpanded, expand }">
        <tr v-if="item.totalPrice - item.totalCost !== 0">
          <td class="text-left">
            <v-btn
              class="no-print"
              @click="expand(true)"
              icon
              v-if="!isExpanded"
              ><v-icon>mdi-chevron-down</v-icon></v-btn
            >
            <v-btn
              class="no-print"
              @click="expand(false)"
              icon
              v-if="isExpanded"
              ><v-icon>mdi-chevron-up</v-icon></v-btn
            >
          </td>

          <td class="text-left text-capitalize">
            <div v-if="item.SalesRep">{{ item.SalesRep.name }}</div>
            <div v-else>Not Selected</div>
          </td>
          <td class="text-right">
            {{ item.totalSale | currency }}
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card color="#F3F8FC" flat>
            <v-card-text class="title">
              <Invoice
                :SalesRepUserId="item.id"
                :start="start"
                :end="end"
              ></Invoice>
            </v-card-text>
          </v-card>
        </td>
      </template>
      <template v-slot:body.append>
        <tr :style="{ 'background-color': $style.listTotal.filter }">
          <td></td>
          <td class="font-weight-bold">Total</td>
          <td class="text-right font-weight-bold">
            {{ totalReportSale | currency }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import saleService from "@/modules/ReportView/service.js";
import invoiceService from "@/modules/Invoice/service";

const Invoice = () => import("@/modules/ReportView/GlobalReport/Invoice.vue");
export default {
  props: ["start", "reset", "end", "CustomerId"],
  name: "salesman-report",
  data() {
    return {
      isLoading: true,
      sortBy: "name",
      User: [],
      Category: [],
      expanded: [],
      expenseExpanded: [],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "SALE",
          align: "right",
          value: "totalSale",
          sortable: true,
        },
      ],
      totalReportSale: 0,
    };
  },
  components: {
    Invoice,
  },
  watch: {
    reset() {
      this.getSaleByCategory();
    },
    CustomerId() {
      this.getSaleByCategory();
    },
  },
  async created() {
    await this.getUserSale();
  },

  methods: {
    color(item) {
      let value = "black";
      let profit = item.totalPrice - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    getUserSale() {
      // console.log("start", this.start);
      // console.log("end", this.end);
      let where = {};
      if (this.start) where.start = this.start;
      if (this.end) where.end = this.end;
      if (this.CustomerId) where.CustomerId = this.CustomerId;
      return invoiceService.invoiceBySalesRep(where).then((result) => {
        console.log("result invoice", result);
        this.User = result.data;
        this.User.map((row) => {
          this.totalReportSale += row.totalSale;
        });
        this.isLoading = false;
      });

      // return saleService.getSaleByCategory(where).then((response) => {
      //   this.Category = response.data;
      //   // // console.log('category', this.Category)
      //   this.Category.map((row) => {
      //     this.totalReportSale += row.totalPrice;
      //     this.totalReportCost += row.totalCost;
      //     let totalSale = this.totalReportSale - this.totalReportCost;
      //     this.$emit("fatchData", totalSale);
      //   });
      //   this.isLoading = false;
      // });
    },
  },
};
</script>

<style scoped></style>
