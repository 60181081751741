var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"dense":"","sort-by":"name","headers":_vm.headers,"itemsPerPage":_vm.User.length,"expanded":_vm.expanded,"show-expand":"","loading":_vm.isLoading,"hide-default-footer":"","items":_vm.User},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.totalPrice - item.totalCost !== 0)?_c('tr',[_c('td',{staticClass:"text-left"},[(!isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left text-capitalize"},[(item.SalesRep)?_c('div',[_vm._v(_vm._s(item.SalesRep.name))]):_c('div',[_vm._v("Not Selected")])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalSale))+" ")])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"color":"#F3F8FC","flat":""}},[_c('v-card-text',{staticClass:"title"},[_c('Invoice',{attrs:{"SalesRepUserId":item.id,"start":_vm.start,"end":_vm.end}})],1)],1)],1)]}},{key:"body.append",fn:function(){return [_c('tr',{style:({ 'background-color': _vm.$style.listTotal.filter })},[_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v("Total")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalReportSale))+" ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }